import React from 'react'
import styled from 'styled-components'
import OnVisible from 'react-on-visible'

import device from '../components/styles/device'
import Map from '../components/map'

import markerImage from '../images/marker.svg'
import mapMaskImage from '../images/map-mask.svg'

const text = {
  de: {
    headline: 'Fragen Sie uns!',
    content:
      'Unser Team berät Sie gerne bei jeglichen Fragen. Verantwortliche Person gemäß § 52a AMG: Mara Brinkmeyer',
  },
  en: {
    headline: 'Questions?',
    content:
      'Our team would be pleased to anwer any and all questions. Your spokesperson: Mara Brinkmeyer (§52a AMG)',
  },
}

const mapProps = {
  options: {
    center: { lat: 52.21942, lng: 8.10419 },
    zoom: 11,
    disableDefaultUI: true,
    streetViewControl: false,
    zoomControl: false,
  },
  onMount: map => {
    const marker = new window.google.maps.Marker({
      position: { lat: 52.21942, lng: 8.10419 },
      map,
      title: 'CreoPharm',
      icon: markerImage,
    })

    marker.addListener('click', evt =>
      window.open(
        'http://maps.google.com/maps?daddr=Bielefelder+Straße+21a,49124+Georgsmarienhütte',
        '_blank'
      )
    )
  },
}

const BoilerplateContainer = styled.section`
  background-color: ${props => props.theme.colorPrimary};
  color: white;
  position: relative;

  a {
    color: white;
  }

  .container {
    position: static;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;

    @media (min-width: 1330px) {
      height: 500px;
      flex-direction: row;
      align-items: center;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: white;
  }
`

const Address = styled.div`
  flex: 1;
  width: 100%;

  @media (min-width: 1330px) {
    max-width: 535px;
  }

  h3 {
    margin-top: 0;
  }

  svg {
    max-width: 69px;
  }
`

const StyledMap = styled.div`
  height: 300px;
  width: calc(100% + 50px);
  margin: 50px -25px -80px -25px;

  @media ${device.tablet} {
    width: calc(100% + 100px);
    margin: 50px -50px -100px -50px;
  }

  @media (min-width: 1330px) {
    margin: 0;
    max-width: 650px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 500px;

    -webkit-mask-image: url(${mapMaskImage});
    mask-image: url(${mapMaskImage});
    mask-size: cover;
    mask-position: top left;
  }
`

const ContactOptions = styled.ul`
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: -5px;
  width: 100%;
  flex-wrap: wrap;

  li {
    margin: 5px;
    flex: 0 1 auto;
  }

  li a {
    display: block;
  }

  @media ${device.tablet} {
    li:last-child {
      margin-left: 20px;
    }
  }

  li:last-child a {
    display: inline;
  }

  li a svg {
    min-width: 69px;
    transition: all 0.25s ease-in-out;
  }

  li a:hover svg {
    transform: scale(1.25);
  }
`

const Boilerplate = ({ lang }) => {
  const { headline, content } = text[lang]

  return (
    <BoilerplateContainer id="contact">
      <div className="container medium-padding">
        <OnVisible percent={20} className="animated fromBottom">
          <Address>
            <h3>{headline}</h3>
            <p>{content}</p>

            <ContactOptions>
              <li>
                <a
                  href="mailto:m.brinkmeyer@creopharm.de"
                  title="Mail to Mara Brinkmeyer"
                >
                  <svg
                    width="68"
                    height="59"
                    viewBox="0 0 68 59"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 29.31L17 0h34l17 29.31-17 29.31H17L0 29.31zm42.207-9.379H25.793a3.517 3.517 0 0 0-3.517 3.517v11.724a3.517 3.517 0 0 0 3.517 3.518h16.414a3.517 3.517 0 0 0 3.517-3.518V23.448a3.517 3.517 0 0 0-3.517-3.517zm0 2.345l-7.62 5.24c-.364.21-.81.21-1.173 0l-7.62-5.24h16.413z"
                      fill="#FFF"
                      fillRule="nonzero"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a href="tel:+4954018814105" title="Mara Brinkmeyer anrufen">
                  <svg
                    width="69"
                    height="60"
                    viewBox="0 0 69 60"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M.31 30l17-29.31h34l17 29.31-17 29.31h-34L.31 30zm40.932 12.99c3.16.028 5.75-2.5 5.798-5.66a5.423 5.423 0 0 0-.312-1.862 1.25 1.25 0 0 0-.9-.812l-7.497-1.712a1.25 1.25 0 0 0-1.15.325c-.174.162-.187.175-1 1.724a12.383 12.383 0 0 1-6.084-6.11c1.537-.8 1.55-.812 1.712-.987a1.25 1.25 0 0 0 .324-1.15l-1.711-7.496a1.25 1.25 0 0 0-.813-.937 4.736 4.736 0 0 0-.9-.225c-.316-.061-.638-.09-.961-.088A5.748 5.748 0 0 0 22 23.748c.014 10.621 8.62 19.228 19.242 19.242z"
                      fill="#FFF"
                      fillRule="nonzero"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="http://maps.google.com/maps?daddr=Bielefelder+Straße+21a,49124+Georgsmarienhütte"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <svg
                    width="69"
                    height="60"
                    viewBox="0 0 69 60"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M.31 30l17-29.31h34l17 29.31-17 29.31h-34L.31 30zM34 15.833c-6.215 0-11.271 5.005-11.333 11.22 0 7.764 9.987 16.405 10.412 16.774.53.453 1.312.453 1.842 0 .496-.369 10.412-9.01 10.412-16.774-.062-6.215-5.118-11.22-11.333-11.22zm0 15.584a4.958 4.958 0 1 1 0-9.917 4.958 4.958 0 0 1 0 9.917z"
                      fill="#FFF"
                      fillRule="nonzero"
                    />
                  </svg>
                </a>
              </li>
              <li>
                Tel.:&nbsp;
                <a href="tel:+4954018814105" title="Mara Brinkmeyer anrufen">
                  +49 (0) 5401 8814-105
                </a>
                <br />
                Mobil:&nbsp;
                <a href="tel:+4915256205046" title="Mara Brinkmeyer anrufen">
                  +49 (0) 152 5620 50 46
                </a>
                <br />
                Mail:&nbsp;
                <a
                  href="mailto:m.brinkmeyer@creopharm.de"
                  title="Mail to Mara Brinkmeyer"
                >
                  m.brinkmeyer@creopharm.de
                </a>
              </li>
            </ContactOptions>
          </Address>
        </OnVisible>

        <StyledMap id="map">
          <Map id="contactMap" {...mapProps} />
        </StyledMap>
      </div>
    </BoilerplateContainer>
  )
}

export default Boilerplate
