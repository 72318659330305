import React from 'react'
import device from './styles/device'
import styled from 'styled-components'
import OnVisible from 'react-on-visible'

import heroImageLarge from '../images/hero--large.jpg'
import heroImageTablet from '../images/hero--tablet.jpg'
import heroImageMobile from '../images/hero--mobile.jpg'

const HeroContainer = styled.div`
  background-color: ${props => props.theme.colorPrimary};
  color: white;
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    flex-direction: row;
    align-items: flex-end;
  }
`

const HeroImageMask = styled.div`
  width: 100%;
  flex: 1;

  @media ${device.tablet} {
    max-width: 50%;
  }
`

const Headline = styled.h1`
  color: white;
  margin: 25px;
  flex: 1;
  max-width: 380px;
  font-size: 3.4rem;

  @media ${device.mobile} {
    font-size: 4.4rem;
  }

  @media ${device.tablet} {
    font-size: 5.4rem;
  }
`

const Hero = props => {
  const lineOne = props.lang === 'en' ? 'GDP-compliant' : 'GDP-konformes'
  const lineTwo = props.lang === 'en' ? 'co-packing of' : 'Co-Packing von'
  const lineThree = props.lang === 'en' ? 'POS displays' : 'POS-Displays'

  return (
    <HeroContainer>
      <HeroImageMask>
        <img
          src={heroImageLarge}
          srcSet={`${heroImageMobile} 375w, ${heroImageTablet} 768w, ${heroImageLarge} 1440w`}
          alt="GDP-konformes Co-Packing von POS-Displays"
        />
      </HeroImageMask>
      <OnVisible className="animated fromBottom">
        <Headline style={{ display: 'block' }}>
          <span style={{ display: 'block' }}>{lineOne}</span>
          <span style={{ display: 'block' }}>{lineTwo}</span>
          <span style={{ display: 'block' }}>{lineThree}</span>
        </Headline>
      </OnVisible>
    </HeroContainer>
  )
}

export default Hero
